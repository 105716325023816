.shows-container{
    margin-top: 50px;
    display: flex;
    padding-left: 3%
}
.flex--1{
    flex:1;
}
.flex--2{
    flex:3
}