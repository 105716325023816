
.app__header{
    /* position: fixed; */
    max-height: 200px;
    background: #28458E;
    width: 100vw;
    z-index: 10;
}

.app__header--logo{
    width: 270px;
    margin-left: 3%;
    margin-bottom: 35px;
    margin-top: 15px;

}

.app_header__action{
    float: right;
    margin-right: 10%;
    margin-top: 3%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 900px) {
    .app__header{
        max-height: 100px;
        width: 100%;
    }
    .app__header--logo{
        width: 28%;
        min-width: 165px;
        margin-left: 50px;
        margin-bottom: 35px;
        margin-top: 15px;
    }
    .app_header__action{
        font-weight: 400 !important;
        font-size: 10px;
        margin-top: 3%;
    }
}
