.show-details__container{
}
.show-details__container .image{
    width: 400px;
    height: 400px;
    background-color: #f6f5f5;

}
.show-details__container .title{
    font-size: 21px;
    margin: 12px 0px;
    color: #4b4a4a;
    font-weight: 600;
}
.show-details__container .description{
    font-size: 18px;
    font-weight: 300;
    color:#4b4a4a;
}