.shows-category__container{

}
.shows-category__container .heading{
    margin-bottom: 40px;
    color: rgb(93, 90, 90);
}
.category-list{

}
.category-list .bullet-icon{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 10px;
    color: #b0b0b0;
    font-weight: 200;
    font-size:  20px;
}
.category-list .category-name{
    color: #b0b0b0;
    font-size: 15px;
    font-weight: 200;
    padding-top: 5px;
}
.category-list .list-items{
    display: flex;
    border-bottom: 1px solid #d5d3d3;
    padding-bottom: 14px;
    max-width: 400px;
    cursor: pointer;
}