.shows-card__outer-container{
    width: 210px;
    height: 360px;
    margin: 12px 24px;
    box-shadow: 0.5px 1px 12px #cccccc, -0.2px -0.2px 12px #cccccc;
    border-radius: 12px;
    background-color: #ffffff;
    cursor: pointer;
}
.shows-img{
    width: 210px;
    height: 180px;
    margin-bottom: 24px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #f6f5f5;
}
.shows-heading{
    font-size: 20px;
    font-weight: 600;
    color: #504f4f;
}
.shows-category{
    font-size: 18px;
    font-weight: 300;
    margin: 0px 10px 15px 10px;
    color: #504f4f;
}
.episode-count{
    font-size: 14px;
    color: #787878;
    font-weight: 400;
}
.text-align--center{
    margin: 0px 10px;
}