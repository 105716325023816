.app--main--container {
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	position: relative;
}

.app-wrapper{
	position: relative;
	padding-top: 11%;
}

.spinner__container{
	position: absolute;
	top:0;
	right:0;
	bottom: 0;
	left: 0;
}
